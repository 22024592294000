/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import ProgressBar from "@ramonak/react-progress-bar";
import { useContext, useEffect, useState, useRef } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Link, useHistory } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import DownloadFormatButtons from "../../components/DownloadFormatButtons";
import { Footer } from "../../components/Footer";
import { GoBack } from "../../components/GoBack";
import { Header } from "../../components/Header";
import { Login } from "../../components/Header/components/Login";
import { ModalDefault } from "../../components/ModalDefault";
import AuthContext from "../../contexts/auth";
import api from "../../services/api";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardList,
  CardRow,
  CardSummary,
  CardSummaryRow,
  Description,
  DownloadOptionsContainer,
  FooterDownloadWrapper,
  ImageDivFallback,
  ImagePreview,
  ImageSelector,
  ImageSelectorContainer,
  ImageThumbnailSelector,
  Label,
} from "./styles";

export function BookInfo(props) {
  const { isLogged, user } = useContext(AuthContext);
  const [data, setData] = useState({});
  const [stage, setStage] = useState(50);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloads, setDownloads] = useState([]);
  const [aux, setAux] = useState(10);
  const [progress, setProgress] = useState(0);
  const [limitDownloadsMonth, setLimitDownloadsMonth] = useState(0);
  const [userDownloads, setUserDownloads] = useState([]);
  const [bookURL, setBookURL] = useState("");
  const [book, setBook] = useState({});
  const [selectedImage, setSelectedImage] = useState({});
  const [loginTitle, setLoginTitle] = useState('Login');

  const [modalError, setModalError] = useState(false);
  const [modalErrorDownload, setModalErrorDownload] = useState(false);
  const [modalInitDownload, setModalInitDownload] = useState(false);
  const [modalReadyToDownload, setModalReadyToDownload] = useState(false);
  const [modalErrorPopup, setModalErrorPopup] = useState(false);
  const [modalLogin, setModalLogin] = useState(false);
  const [modalWarning, setModalWarning] = useState(false);

  const [selectedFormat, setSelectedFormat] = useState("");

  const history = useHistory();

  const focus = useRef();

  const { match } = props;

  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const extractedID = match.params.id;
  const id = Number.isNaN(parseInt(extractedID)) ? -1 : extractedID;

  const [targetImageSize, setTargetImageSize] = useState({
    width: 120,
    height: 250,
  });

  const fetchUserCustomDownloads = async () => {
    try {
      if (isLogged) {
        const response = await api.get("/users/me");

        if (response.status === 200) {
          if (Object.keys(data).length === 0) setData(response.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchForCurrentBook = async () => {
    try {

      const response = await api.get(`/books/${id}`);

      if (response.status === 200) {
        setBook(response.data);
        setSelectedImage(response.data.images[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedImage) {
      if (!selectedImage.file) return;
      const newImage = document.createElement("img");
      newImage.setAttribute("src", selectedImage.file.url);

      newImage.onload = () => {
        const proportion = newImage.width / newImage.height;

        setTargetImageSize({
          width: proportion * 250,
          height: 250,
        });
      };
    }
  }, [selectedImage]);

  useEffect(() => {
    fetchUserCustomDownloads();
  }, [isLogged])

  useEffect(() => {
    fetchForCurrentBook();
  }, [])

  useEffect(() => {
    if (book?.description) {
      focus.current?.focus();
    }
  }, [book]);

  function getStartOfMonth() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const firstDayOfMonth = new Date(year, month, 1);
    const isoString = firstDayOfMonth.toISOString();
    return isoString.split('T')[0];
  }

  const fetchForUserHistory = async () => {
    const formattedStartDate = getStartOfMonth();
    try {
      const response = await api.get(
        `/user-histories?user.id=${user.id}&_limit=-1&created_at_gte=${formattedStartDate}`
      );
      if (response) {
        if (response.status === 200) {
          const downloadHistory = response.data.filter(
            ({ type }) => type === "download"
          );
          setDownloads(downloadHistory);
          return;
        }
        throw new Error("Unauthorized");
      }
      throw new Error("Unexpected response");
    } catch (error) {
      console.error(error, "error while fetching for user history");
    }
  };

  useEffect(() => {
    if (user.id !== undefined) fetchForUserHistory();
  }, [user, reload]);

  useEffect(() => {
    handleProgressUpdate(aux);
  }, [aux])

  useEffect(() => {
    if (selectedImage) {
      if (!selectedImage.file) return;
      const newImage = document.createElement("img");
      newImage.setAttribute("src", selectedImage.file.url);

      newImage.onload = () => {
        const proportion = newImage.width / newImage.height;

        setTargetImageSize({
          width: proportion * 250,
          height: 250,
        });
      };
    }
  }, [stage]);

  // if (book.id === undefined) {
  //   return null;
  // }

  useEffect(() => {
    let limit = new Date(user.birthDate).getMonth() === currentMonth ? 6 : 3;
    setLimitDownloadsMonth(limit);

    let userDownloadsfilted = data.download_limits?.filter(
      (d) =>
        d.Mes === parseInt(currentMonth) + 1 && d.Ano === parseInt(currentYear)
    );
    setUserDownloads(userDownloadsfilted);

    if (userDownloadsfilted && userDownloadsfilted[0]) {
      limit += userDownloadsfilted[0].Downloads;
      setLimitDownloadsMonth(limit);
    }
  }, [data]);

  const getDownloadsFilter = () => {
    let filter = downloads.length >= limitDownloadsMonth;

    if (
      user.id === undefined ||
      book.license?.id === 1 ||
      book?.license === 1
    )
      return false;

    return filter;
  };

  function setFormat(selectedFormat) {
    switch (selectedFormat) {
      case "audiobook":
        return "audiobookDigital";
      case "braille":
        return "brailleDigital";
      case "html5":
        return "HTML5";
      case "epub":
        return "ePubDigital";
      case "daisy":
        return "daisyDigital";
      default:
        break;
    }
  }

  const handlePostDownloadCountWithUserHistory = async (url = null) => {
    try {
      const userHistoryForm = {
        type: "download",
        book: {
          id: book.id,
        },
        user: {
          id: user.id,
        },
        author: book.author,
        title: book.bookTitle,
        formatType: setFormat(selectedFormat.toLowerCase()),
      };
      await api.post(`/user-histories`, userHistoryForm);

      setReload(true);

      await api.post(`/books/download-increment`, {
        bookId: book.id,
      });

      window.open(url);
      setModalReadyToDownload(false);
    } catch (error) {
      console.error(
        error,
        "error while trying to save user history with download post"
      );
    }
  };

  const formatDictionary = {
    Audiobook: "Audiolivro",
  };

  const handleDownload = (formatSelected) => {
    setLoading(true);
    setSelectedFormat(formatSelected);
    if (getDownloadsFilter()) {
      setModalError(true);
      return;
    }

    const targetFile = book[formatSelected.toLowerCase()];

    if (handlePopupOpen()) {
      handleCloseModalPopUp();
      return;
    }

    if (!targetFile) return;

    if (book.license?.id === 1 || book?.license === 1) {
      window.open(targetFile.url);
      setLoading(false);
    } else {
      handleSteganographyAndDownload(formatSelected);
    }
  };

  async function requestSteganography(postData) {

    setAux(prevAux => {
      const newAux = prevAux + 5;
      handleProgressUpdate(newAux);
      return newAux;
    });

    setTimeout(async () => {
      try {
        await doRequest(postData)
          .then(async (res) => {

            if (res?.body?.url) {
              if (!handlePopupOpen()) {
                handleProgressUpdate(100);
                setBookURL(res.body.url);
                setModalInitDownload(false);
                setModalReadyToDownload(true);
                setLoading(false);
              } else {
                setModalErrorPopup(true);
              }
            } else {
              postData.operation = "getToken";
              requestSteganography(postData);
            }
          })
      } catch (e) {
        postData.operation = "getToken";
        requestSteganography(postData);
      }
    }, postData.operation == 'saveToken' ? 1000 : 30000)
  }

  const handleSteganographyAndDownload = async (formatSelected) => {
    setLoading(true);
    let today = new Date();
    let messageSteganography = `O usuário ${user.name + " " + user.lastName
      }, do e-mail ${user.email} (id ${user.id
      }), realizou o download desse livro em ${today.getDate()}/${today.getMonth() + 1
      }/${today.getFullYear()} as ${today.getHours()}:${today.getMinutes()}`;
    const targetFile = book[formatSelected.toLowerCase()];

    const postData = {
      url: targetFile.url,
      message: messageSteganography,
      operation: "saveToken",
      token: new Date().getTime().toString(),
    };

    setModalInitDownload(true);
    requestSteganography(postData);
  };

  async function doRequest(data) {
    let urlLambda =
      process.env.REACT_APP_PRODUCTION_ENVIRONMENT === "true"
        ? "https://3e3if6u7e2.execute-api.us-east-1.amazonaws.com/prod/encrypt-files-apidorinateca-prod"
        : "https://pi196u65z6.execute-api.us-east-1.amazonaws.com/homologacao/encrypt-files-hhh-apidorinateca";

    const response = await fetch(urlLambda, {
      method: "POST",
      body: JSON.stringify(data),
    });

    return response.json();
  }

  const summary = [
    {
      label: "Editora",
      value: book.publisher,
    },
    {
      label: "Categorias",
      value: (book.categories ?? []).map(({ name }) => name).join(", "),
    },
    {
      label: "Palavras-chave",
      value: (
        book.keywords ??
        (book.keyword ? [book.keyword] : [])
      )
        .map(({ name }) => name)
        .join(", "),
    },
    {
      label: "Nº de páginas",
      value: book.pages,
    },
    {
      label: "Data de publicação",
      value: book.publishedDate,
    },
    {
      label: "Local",
      value: book.local,
    },
    {
      label: "Licença",
      value: book.license?.name,
    },
  ];

  const availableFormats = ["Braille", "Audiobook", "Daisy", "ePub"]
    .filter((item) => !!book[item.toLowerCase()])
    .map((item) => formatDictionary[formatDictionary] ?? item);

  const handleProgressUpdate = (newProgress) => {
    setProgress(Math.min(newProgress, 100));
  };

  const downloadSteganographyBook = async () => {
    await handlePostDownloadCountWithUserHistory(bookURL);
  };

  const handlePopupOpen = () => {
    var popUp = window.open('/pop.html', 'Popup Test', 'width=100, height=100, left=24, top=24, scrollbars, resizable');
    if (popUp == null || typeof (popUp) == 'undefined') {
      return true;
    }
    else {
      popUp.focus();
      popUp.close();
    }
    return false
  };

  //quando o modal de aviso do pop-up for fechar, vai chamar essa função que vai liberar mais um download para o usuário no mês
  const handleCloseModalPopUp = async () => {
    setModalErrorPopup(false)
    try {
      if (Object.keys(user).length > 0) {
        if (userDownloads && userDownloads[0]) {
          const limitsWithoutThisMonth = user.download_limits.filter(limit => limit.id !== userDownloads[0].id)
          await api.put(`/users/${user.id}`, {
            download_limits: [
              ...limitsWithoutThisMonth,
              {
                id: userDownloads[0].id,
                Ano: userDownloads[0].Ano,
                Mes: userDownloads[0].Mes,
                Downloads: userDownloads[0].Downloads + 1,
              }
            ]
          })
        } else {
          const limitsWithoutThisMonth = user.download_limits.filter(limit => limit.id !== userDownloads[0].id)
          await api.put(`/users/${user.id}`, {
            download_limits: [
              ...limitsWithoutThisMonth,
              {
                Ano: new Date().getFullYear(),
                Mes: new Date().getMonth() + 1,
                Downloads: limitDownloadsMonth + 1,
              }
            ]
          })
        }
      }
    } catch (error) {
      console.error(error)
    }
    history.push("/buscar");
  }
  return (
    <>
      <Header />
      <GoBack ariaLabel={"Voltar para página anterior"} title="Publicação" />
      <ModalDefault
        type="error"
        title="Download não realizado!"
        isOpen={modalErrorDownload}
        onRequestClose={() => setModalErrorDownload(false)}
        showCloseButton
      >
        <p>
          Ocorreu um erro ao processar o arquivo para o download, recarregue a
          página e tente novamente.
        </p>
      </ModalDefault>
      <ModalDefault
        type="error"
        title="Download não realizado!"
        isOpen={modalError}
        onRequestClose={() => setModalError(false)}
        showCloseButton
      >
        <p>O usuário excedeu o limite mensal de downloads.</p>
      </ModalDefault>
      <ModalDefault
        type="error"
        title="Iniciando liberação do arquivo"
        isOpen={modalInitDownload}
        onRequestClose={() => {
          setModalInitDownload(false);
        }}
        showCloseButton={false}
        shouldCloseOnOverlayClick={false}
      >
        <p>
          O arquivo esta sendo processado e logo será disponibilizado para
          download, isso pode levar alguns minutos, não saia nem recarregue a
          página até o processo ser concluído.
        </p>
        <ProgressBar
          completed={progress}
          height="35px"
          width="300px"
          labelColor="#ffffff"
          labelSize="20px"
          margin="10px 0"
          transitionDuration="500ms"
          transitionTimingFunction="linear"
          animateOnRender
          aria-label={`Processamento ${progress}% completo`}
        />
      </ModalDefault>
      <ModalDefault
        type="error"
        title="Processamento Concluído"
        isOpen={modalReadyToDownload}
        showCloseButton={false}
        shouldCloseOnOverlayClick={false}
      >
        <p style={{ margin: "16px 0 8px" }}>
          Clique abaixo para obter seu livro. <br />
          Obrigado por utilizar a Dorinateca.
        </p>
        <FiCheckCircle
          size={66}
          className="green-icon"
          aria-label="Ícone de sucesso"
        />
        <Button onClick={downloadSteganographyBook} aria-label="Baixar livro">
          Obter livro
        </Button>
      </ModalDefault>
      <ModalDefault
        type="error"
        title="Atenção!"
        isOpen={modalErrorPopup}
        onRequestClose={handleCloseModalPopUp}
        showCloseButton
      >
        <p style={{ margin: "8px 0" }}>
          Identificamos que algumas permissões em seu navegador não foram
          habilitadas. <br />
          Para prosseguir com o uso da Dorinateca, consute nosso{" "}
          <Link to="/perguntas-frequentes" style={{}}>FAQ</Link>. <br />
          No artigo de liberação de pop-ups teremos o instrutivo para solucionar
          essa questão.
        </p>
      </ModalDefault>
      <ModalDefault
        type="warning"
        title="Atenção!"
        isOpen={modalWarning}
        onRequestClose={() => setModalWarning(false)}
      >
        <label ref={focus}>Para prosseguir com o uso da Dorinateca, é necessário fazer o login. <br /> Clique abaixo para continuar:</label>
        <Button onClick={() => {
          setModalWarning(false)
          setModalLogin(true)
        }}>Efetuar Login</Button>
      </ModalDefault>
      <ModalDefault type="login" title={loginTitle} isOpen={modalLogin}
        onRequestClose={() => setModalLogin(false)}>
        <Login
          close={() => setModalLogin(false)}
          setLoginTitle={setLoginTitle}
        />
      </ModalDefault>
      <CardRow>
        <ImageSelector>
          <ImagePreview>
            <ImageDivFallback
              aria-label={selectedImage?.caption}
              bgImage={
                selectedImage
                  ? selectedImage.file
                    ? selectedImage.file?.url
                    : undefined
                  : undefined
              }
              style={{
                width: targetImageSize.width,
                height: targetImageSize.height,
              }}
            />
          </ImagePreview>
          <ImageSelectorContainer>
            {book.images?.map((item, key) => (
              <ImageThumbnailSelector
                onClick={() => (key !== stage ? setStage(key) : null)}
                active={key === stage}
                key={key + "imgselector"}
              />
            ))}
          </ImageSelectorContainer>
        </ImageSelector>
        <Card>
          <CardHeader>
            <h2 tabIndex={0}>{book.bookTitle}</h2>
            <p tabIndex={0}>{book.author}</p>
            <p tabIndex={0}>{book.release}</p>
            <CardSummary>
              <h4 tabIndex={0}>Sumário</h4>
              <p ref={focus} tabIndex={0} aria-description='Detalhes da obra'>{book.description}</p>
            </CardSummary>
          </CardHeader>
          <CardList>
            {summary.map(({ label, value }, key) => {
              return (
                <CardSummaryRow key={key + label}>
                  <Label tabIndex={0}>{label}</Label>
                  <Description tabIndex={0}>{value}</Description>
                </CardSummaryRow>
              );
            })}
          </CardList>
          <CardFooter>
            <FooterDownloadWrapper>
              <h4 tabIndex={0}>Formatos disponíveis</h4>
              <DownloadOptionsContainer>
                {isLogged ?
                  <DownloadFormatButtons
                    availableFormats={availableFormats}
                    onClick={(selected) => handleDownload(selected)}
                    loading={loading}
                  /> :
                  <DownloadFormatButtons
                    availableFormats={availableFormats}
                    onClick={() => setModalWarning(true)}
                    loading={loading}
                  />}
              </DownloadOptionsContainer>
            </FooterDownloadWrapper>
          </CardFooter>
        </Card>
      </CardRow>
      <Footer />
    </>
  );
}
